body {
    margin: 0;
    font-family: "Patrick Hand", "Frutiger Neue For Post", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--yellow);
}

code {
    font-family: "Patrick Hand", "Frutiger Neue For Post", source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}